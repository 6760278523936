import React from "react";
import "./Header.css";
import Logo from "../../../assets/logo.png";
import HamburgerOpen from "../../../assets/hamburger.png";
import HamburgerClose from "../../../assets/hamburgerClose.png";
import PhoneIcon from "./../../../assets/phoneIcon.png"
import { Link } from "react-router-dom";

export default class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      width: null,
      height: null,
      isMobileShown: false,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  showMobileMenue = () => {
    this.setState({ isMobileShown: !this.state.isMobileShown });
  };

  render() {
    let currentHamburger;
    if (!this.state.isMobileShown) {
      currentHamburger = HamburgerOpen;
    } else {
      currentHamburger = HamburgerClose;
    }
    return (
      <div className="Header">
        <img className="header-logo" src={Logo} alt="Logo" />

        {this.state.width < 768 ? (
          <img
            className="header-hamburger-menue"
            src={currentHamburger}
            alt="Menue"
            onClick={this.showMobileMenue}
          />
        ) : (
          <div>
            <a href="tel:048327128">
              <div className="header-button"><img className="header-btn-icon" src={PhoneIcon} alt="..." />04832/7128</div>
            </a>

            <Link to="/downloads">
              <p
                className={
                  "header-element " +
                  (this.props.active === "3" ? "header-active" : "")
                }
              >
                Downloads
              </p>
            </Link>
            {/*<Link to="/click-and-meet">
              <p
                className={
                  "header-element " +
                  (this.props.active === "2" ? "header-active" : "")
                }
              >
                Click & Meet
              </p>
              </Link>*/}
            <Link to="/">
              <p
                className={
                  "header-element " +
                  (this.props.active === "1" ? "header-active" : "")
                }
              >
                Home
              </p>
            </Link>
          </div>
        )}
        {this.state.isMobileShown ? (
          <div className="header-mobile-content">
            <Link to="/">
              <p
                className={
                  "header-mobile-element " +
                  (this.props.active === "1" ? "header-mobile-active" : "")
                }
              >
                Home
              </p>
            </Link>
            <Link to="/click-and-meet">
              <p
                className={
                  "header-mobile-element " +
                  (this.props.active === "2" ? "header-mobile-active" : "")
                }
              >
                Click & Meet
              </p>
            </Link>
            <Link to="/downloads">
              <p
                className={
                  "header-mobile-element " +
                  (this.props.active === "3" ? "header-mobile-active" : "")
                }
              >
                Downloads
              </p>
            </Link>
            <div className="header-mobile-bottom-row">
              <a href="tel:048327128">
                <div className="header-mobile-bottom-row-call"><img className="header-btn-icon" src={PhoneIcon} alt="..." />04832/7128</div>
              </a>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
