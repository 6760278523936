import React from "react";
import "./TextBox.css";

export default class TextBox extends React.Component {
  render() {
    return (
      <div className="TextBox">
        <p className="textbox-paragraph">
          <b>
            <span className="textbox-big-span">Aktuelle Informationen:</span>
          </b>
          <br />
          <br />
          Bei uns im Ladengeschäft sind Ventilatoren und Klimageräte vorrätig.
          <br />
          <br />
        </p>
      </div>
    );
  }
}
