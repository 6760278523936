import React from "react";
import "./HeadlineCombo.css";
export default class HeadlineCombo extends React.Component {
  render() {
    return (
      <div className="HeadlineCombo">
        <p className="headline-combo-subheadline">{this.props.subheadline}</p>
        <p className="headline-combo-headline">{this.props.headline}</p>
      </div>
    );
  }
}
