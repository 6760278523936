import React from "react";
import "./Footer.css";
import Logo from "../../../assets/logo.png";
import { Link } from "react-router-dom";

export default class Footer extends React.Component {

  getYear = () => {
    return new Date().getFullYear();
  }

  render() {
    return (
      <div className="Footer">
        <div className="footer-left-logo">
          <img className="footer-logo" src={Logo} alt="Logo" />
          <p className="footer-legal-paragraph">
            Copyright &copy; {this.getYear()} Christiansen Elektronik &bull; Alle Rechte
            vorbehalten.
          </p>
        </div>

        <div className="footer-right">
          <div className="footer-menue-points">
            <div className="footer-menue-row">
              <p className="footer-adress-paragraph">
                Christiansen Elektronik
                <br />
                Österstraße 41-43
                <br />
                25704 Meldorf
                <br />
                04832/7128
                <br />
                info@christiansen-meldorf.de
                <br />
              </p>
            </div>
            <div className="footer-menue-row">
              <Link to="/impressum">
                <p className="footer-menue-element">Impressum</p>
              </Link>
              <Link to="/datenschutz">
                <p className="footer-menue-element">Datenschutz</p>
              </Link>
            </div>
            <div className="footer-menue-row">
              <Link to="/">
                <p className="footer-menue-element">Home</p>
              </Link>
              {/* <Link to="/click-and-meet">
                <p className="footer-menue-element">Click & Meet</p>
              </Link>
              */}
              <Link to="/downloads">
                <p className="footer-menue-element">Downloads</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
