import React from "react";
import "./MultipleRow.css";
export default class ModoloooMultipleRow extends React.Component {
  render() {
    return (
      <div className="TripleRow">
        {this.props.elements.map((e, i) => (
          <div
            className="multiple-row-element"
            style={{ width: 100 / this.props.elements.length + "%" }}
            key={Math.random()}
          >
            <div className="multiple-row-element-inner">
              {e.img !== null ? (
                <img
                  className="multiple-row-element-inner-image"
                  src={e.img}
                  alt="..."
                />
              ) : null}
              {i !== 2 ? (
                <p className="multiple-row-element-inner-headline" lang="de">
                  {e.headline}
                </p>
              ) : (
                <p className="multiple-row-element-inner-headline">
                  {e.headline}
                </p>
              )}
              <p className="multiple-row-element-inner-description">
                {e.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
