import React from "react";
import "./css/Downloads.css";
import Header from "./sub-components/header/Header";
import Footer from "./sub-components/footer/Footer";
import Brunsbuettel from "../assets/brunsbuettel.png";
import Heide from "../assets/heide.png";
import Hemmingstedt from "../assets/hemmingstedt.png";
import Open from "../assets/openPdf.png";
import VerticalSpacer from "./sub-components/vertical-spacer/VerticalSpacer";
import ContactBar from "./sub-components/contact-bar/ContactBar";
import TimeIcon from "./../assets/timeIcon.png";


import ListHeide from "../assets/Senderliste-Heide.pdf";
import ListBrunsbuettel from "../assets/Senderliste-Brunsbuettel.pdf"
import ListHemmingstedt from "../assets/Senderliste-Hemmingstedt.pdf";

import Fade from "react-reveal/Fade";
export default class Leistungen extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Downloads">
         <ContactBar
          text1={
            <>
              <img className="contactbar-icon" src={TimeIcon} alt="..." />{" "}
              Mo.-Fr. 08:30-12:00 & 13:00-18:00 | Sa. 09:00-12:00
            </>
          }
        />
        <Header active="3"/>
        <div className="downloads-inner">
          <div className="small-hero">
            <p className="small-hero-h">Downloads</p>
            <p className="small-hero-sub-h">
              Nachfolgend finden Sie die Senderlisten für die Orte Heide,
              Brunsbüttel & Hemmingstedt zum Download bereitgestellt.
            </p>
          </div>
          <Fade bottom>
            <div className="downloads-centered">
              <VerticalSpacer height="200px" />
              <a href={ListHeide} target="_blank" rel="noreferrer">
                <div className="downloads-row-element">
                  <img
                    className="downloads-row-element-img"
                    src={Heide}
                    alt="..."
                  />
                  <p className="downloads-row-element-text">
                    Senderliste Heide
                  </p>
                  <img
                    className="downloads-row-element-open-img"
                    src={Open}
                    alt="..."
                  />
                </div>
              </a>
              <a href={ListBrunsbuettel} target="_blank" rel="noreferrer">
                <div className="downloads-row-element">
                  <img
                    className="downloads-row-element-img"
                    src={Brunsbuettel}
                    alt="..."
                  />
                  <p className="downloads-row-element-text">
                    Senderliste Brunsbüttel
                  </p>
                  <img
                    className="downloads-row-element-open-img"
                    src={Open}
                    alt="..."
                  />
                </div>
              </a>
              <a href={ListHemmingstedt} target="_blank" rel="noreferrer">
                <div className="downloads-row-element">
                  <img
                    className="downloads-row-element-img"
                    src={Hemmingstedt}
                    alt="..."
                  />
                  <p className="downloads-row-element-text">
                    Senderliste Hemmingstedt
                  </p>
                  <img
                    className="downloads-row-element-open-img"
                    src={Open}
                    alt="..."
                  />
                </div>
              </a>
            </div>
            <VerticalSpacer height="200px" />
            <div style={{ clear: "both" }}></div>
          </Fade>
        </div>

        <Footer />
      </div>
    );
  }
}
