import React from "react";
import "./ContactBar.css";

export default class ContactBar extends React.Component {
  render() {
    return (
      <div className="ContactBar">
          <p className="contactbar-text-element">
           {this.props.text1}
          </p>
      </div>
    );
  }
}
