import './App.css';
/* import ClickAndMeet from './components/ClickAndMeet'; */
import Home from './components/Home';
import Downloads from './components/Downloads';
import Datenschutz from "./components/Datenschutz";
import Impressum from "./components/Impressum";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import FourZeroFour from './components/FourZeroFour';

function App() {
  return (
    <BrowserRouter basename="">
        <div className="App">
          <Switch>
              <Route exact path="/" render={() => <Home />} />
              {/*<Route exact path="/click-and-meet" render={() => <ClickAndMeet />} />*/}
              <Route exact path="/downloads" render={() => <Downloads />} />
              {/*<Route exact path="/delete/:uuid" render={(props) => <Delete {...props}/>} />*/}
              <Route exact path="/impressum" render={() => <Impressum />} />
              <Route exact path="/datenschutz" render={() => <Datenschutz />} />
              <Route component={FourZeroFour} />
          </Switch>
        </div>
      </BrowserRouter>
  );
}

export default App;
