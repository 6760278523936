import React from "react";
import "./HalfHalf.css";

export default class ModoloooHalfHalf extends React.Component {
  render() {
    if (this.props.imageSite === "left") {
      return (
        <div className="HalfHalf">
          <div
            className="half-half-half-img half-half-float-left"
            style={{ backgroundImage: `url(${this.props.img})` }}
          ></div>
          <div className="half-half-half half-half-float-right"> 
              <p className="half-half-headline">{this.props.headline}</p>
              <p className="half-half-paragraph">{this.props.text}</p>
          </div>
          <div style={{ clear: "both" }}></div>
        </div>
      );
    } else if (this.props.imageSite === "right") {
      return (
        <div className="HalfHalf">
          <div
            className="half-half-half-img half-half-float-right"
            style={{ backgroundImage: `url(${this.props.img})` }}
          ></div>

          <div className="half-half-half half-half-float-left">
              <p className="half-half-headline">{this.props.headline}</p>
              <p className="half-half-paragraph">{this.props.text}</p>
          </div>

          <div style={{ clear: "both" }}></div>
        </div>
      );
    }
  }
}
