import React from "react";
import "./css/Home.css";
import HeroImage from "./../assets/Hero.png";
import ContactBar from "./sub-components/contact-bar/ContactBar";
import HalfHalf from "./sub-components/half-half/HalfHalf";
import Header from "./sub-components/header/Header";
import Hero from "./sub-components/hero/Hero";
import TextBox from "./sub-components/text-box/TextBox";
import ModernHalfHalf from "./sub-components/modern-half-half/ModernHalfHalf";
import Footer from "./sub-components/footer/Footer";
import HeadlineCombo from "./sub-components/headline-combo/HeadlineCombo";
import MultipleRow from "./sub-components/multiple-row/MultipleRow";
import ContactBox from "./sub-components/contact-box/ContactBox";
import VerticalSpacer from "./sub-components/vertical-spacer/VerticalSpacer";
import ElektroImg from "./../assets/electroShape.png";
import TVImg from "./../assets/tvShape.png";
import RadioImg from "./../assets/radioShape.png";
import ContactImg from "./../assets/contactImg.jpg";
import MailIcon from "./../assets/mailIcon.png";
import PhoneIcon from "./../assets/phoneIcon.png";
import TimeIcon from "./../assets/timeIcon.png";
import WrenchIcon from "./../assets/wrench.png";
import MotherbordImg from "./../assets/motherboardShape.png";

// ANIMATIONS
import Fade from "react-reveal/Fade";

// IMAGES
import FlashIcon from "./../assets/flashIcon.png";
import ReqIcon from "./../assets/requisitenIcon.png";
import EntertainmentIcon from "./../assets/entertainmentIcon.png";
import Front from "./../assets/front.jpg";

export default class Home extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Home">
        <ContactBar
          text1={
            <>
              <img className="contactbar-icon" src={TimeIcon} alt="..." />{" "}
              Mo.-Fr. 08:30-12:00 & 13:00-18:00 | Sa. 09:00-12:00
            </>
          }
        />
        <Header active="1" />
        <Hero
          img={HeroImage}
          title={<>Christiansen Elektronik</>}
          subtitle={"HERZLICH WILLKOMMEN BEI"}
          /*btnText={"Termin buchen"}
                  btnLink={"/click-and-meet"}*/
        />

        <Fade left>
          <TextBox />
        </Fade>

        <Fade bottom>
          <VerticalSpacer height="200px" />
        </Fade>

        <Fade bottom>
          <HeadlineCombo
            headline="Unsere Leistungen"
            subheadline="IM HERZEN VON MELDORF"
          />
        </Fade>

        <Fade bottom>
          <MultipleRow
            elements={[
              {
                headline: "Elektrotechnik",
                description:
                  "Planung und Ausführung von Elektroarbeiten. Installation von Wallboxen und E-Speichern sowie Durchführung von E-Checks.",
                img: FlashIcon,
              },
              {
                headline: "Informationselektronik",
                description:
                  "Installation von SAT-, DVB-T- und Kabelanlagen, Smart-Home Geräten und ELA-Anlagen. Durchführung von Netzwerkarbeiten.",
                img: EntertainmentIcon,
              },
              {
                headline: "Reparaturen / Ersatzteilservice",
                description:
                  "Reparatur und Wartung jeglicher Elektro Klein- und Großgeräte. Ersatzteilbeschaffung jeglicher Art für Bastler.",
                img: WrenchIcon,
              },
              {
                headline: "Requisiten",
                description:
                  "Verleih von Requisiten elektronischer Art von 1900 bis zur heutigen Zeit.",
                img: ReqIcon,
              },
            ]}
          />
        </Fade>

        <Fade bottom>
          <VerticalSpacer height="200px" />
        </Fade>

        <Fade bottom>
          <ModernHalfHalf img={Front} orientation="left" />
        </Fade>

        <VerticalSpacer height="400px" />

        <Fade left>
          <HalfHalf
            imageSite="left"
            img={ElektroImg}
            headline="Elektrotechnik"
            text="Wir installieren in Ihrem Privathaus oder in Ihrem Gewerbebetrieb Kameraüberwachung/Alarmanlagen oder Steuerungselemente im Bereich der Elektrotechnik. Verkauf von Elektro Großgeräten – z. B. Waschmaschinen, Geschirrspüler und Staubsauger."
          />
          <VerticalSpacer height="72px" />
        </Fade>

        <Fade right>
          <HalfHalf
            imageSite="right"
            img={TVImg}
            headline={
              <>
                Informations-
                <br />
                elektronik
              </>
            }
            text="Wir bieten qualitativ hochwertige Geräte nach dem neuesten Standard an und stellen auch aus verschieden Komponenten - z. B. Fernseher, Smart-Home Geräten und HiFi-Anlagen - passgenau für Sie zusammen. Wir stellen ihnen Lösungen für Sat-, DVB-T- und Kabelanlagen, Netzwerkarbeiten und ELA-Anlagen gerne für sie zusammen."
          />
          <VerticalSpacer height="72px" />
        </Fade>

        <Fade left>
          <HalfHalf
            imageSite="left"
            img={MotherbordImg}
            headline={
              <>
                Reparaturen/
                <br />
                Ersatzteilservice
              </>
            }
            text="Wir reparieren und warten ihre Geräte vom Bügeleisen bis zur Waschmaschine. Wenn ihnen mal Ersatzteile fehlen fragen sie uns gern, wir helfen ihnen weiter."
          />
          <VerticalSpacer height="72px" />
        </Fade>

        <Fade right>
          <HalfHalf
            imageSite="right"
            img={RadioImg}
            headline={<>Requisiten</>}
            text="Ob Nostalgiefeier, Theaterstück oder Kinofilm - unsere Sammlerstücke aus einem Jahrhundert Radio- und Fernsehgeschichte haben schon viele Menschen gesehen. Auch Requisiteure von Fernsehproduktionen greifen gern auf unsere Exponate zurück."
          />
        </Fade>

        <Fade bottom>
          <VerticalSpacer height="200px" />
        </Fade>

        <Fade bottom>
          <ContactBox
            img={ContactImg}
            headline={
              <>
                Sprechen Sie
                <br />
                uns an!
              </>
            }
            text={
              <>
                <a style={{ color: "white" }} href="tel:04832/7128">
                  <img
                    className="contact-box-inline-img"
                    src={PhoneIcon}
                    alt="..."
                  />
                  <span className="contact-box-inline-text">04832/7128</span>
                </a>
                <br />
                <a
                  style={{ color: "white" }}
                  href="mailto:info@christiansen-meldorf.de"
                >
                  <img
                    className="contact-box-inline-img"
                    src={MailIcon}
                    alt="..."
                  />
                  <span className="contact-box-inline-text">
                    info@christiansen-meldorf.de
                  </span>
                </a>
              </>
            }
          />
        </Fade>
        <Footer />
      </div>
    );
  }
}
