import React from "react";  
import "./ContactBox.css"

export default class ModoloooContactBox extends React.Component {  
  render() {  
    return (  
      <div className="ContactBox">
          <div className="contactbox-image-box" style={{ backgroundImage: `url(${this.props.img})`}}></div>
          <div className="contactbox-text-box">
              <p className="contactbox-headline">{this.props.headline}</p>
              <p className="contactbox-text">{this.props.text}</p>
          </div>
      </div>  
    );  
  }  
}  