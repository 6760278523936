import React from "react";
import "./css/FourZeroFour.css"
import FourZeroFourIcon from "./../assets/404Icon.png";

export default class FourZeroFour extends React.Component {
  render() {
    return (
      <div className="FourZeroFour">
        <div className="fourzerofour-inner">
            <img className="fourzerofour-image" src={FourZeroFourIcon} alt="..."/>
            <p className="fourzerofour-paragraph"><b><span style={{fontSize: "2em"}}>Oje :(</span></b><br/>die von dir gesuchte Seite existiert leider nicht.</p>
        </div>
      </div>
    );
  }
}
