import React from "react";
import "./Hero.css";
export default class Hero extends React.Component {
  render() {
    return (
      <div className="Hero">
        <div
          className="hero-inner"
          style={{ backgroundImage: `url(${this.props.img})` }}
        >
          <p className="hero-sub-title">{this.props.subtitle}</p>
          <p className="hero-title">{this.props.title}</p>
          {/*<Link to={this.props.btnLink}>
            <div className="hero-button">{this.props.btnText}</div>
          </Link>*/}
        </div>
      </div>
    );
  }
}
