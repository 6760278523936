import React from "react";
import "./css/ImpressumDatenschutz.css";
import Header from "./sub-components/header/Header";
import Footer from "./sub-components/footer/Footer";

export default class Impressum extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Impressum">
        <Header />

        <div className="imp-dat-inner">
          <p className="imp-dat-big-h">Impressum</p>
          <p className="imp-dat-sub-h">Inhaltlich verantowrtlich</p>
          <p className="imp-dat-text">
            Christiansen Elektronik
            <br />
            Österstraße 43
            <br />
            25704 Meldorf
            <br />
            Telefon: 04832/7128
            <br />
          </p>

          <p className="imp-dat-sub-h">Haftung für Links</p>
          <p className="imp-dat-text">
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
            Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
            nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </p>

          <p className="imp-dat-sub-h">Urheberrecht</p>
          <p className="imp-dat-text">
            Die durch uns erstellten Inhalte und Werke auf diesen Seiten
            unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
            Bearbeitung, Verbreitung sowie jede Art der Verwertung außerhalb der
            Grenzen des Urheberrechtes sind nur mit der schriftlichen Zustimmung
            von uns erlaubt. Downloads und Kopien dieser Seite für den privaten,
            nicht kommerziellen Gebrauch sind gestattet. Soweit Inhalte auf
            dieser Seite nicht von uns erstellt wurden, werden die Urheberrechte
            Dritter beachtet. Insbesondere sind Inhalte Dritter als solche
            gekennzeichnet. Sollten Sie dennoch auf eine Urheberrechtsverletzung
            aufmerksam werden, bitten wir Sie um einen entsprechenden Hinweis,
            sodass wir derartige Inhalte bei Bekanntwerden umgehend entfernen
            können.
          </p>
        </div>

        <Footer />
      </div>
    );
  }
}
