import React from "react";
import "./css/ImpressumDatenschutz.css";
import Header from "./sub-components/header/Header";
import Footer from "./sub-components/footer/Footer";

export default class Datenschutz extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="Datenschutz">
        <Header />

        <div className="imp-dat-inner">
          <p className="imp-dat-big-h">Datenschutz</p>
          <p className="imp-dat-sub-h">1. Einführung und Rechtsgrundlagen</p>
          <p className="imp-dat-text">
            In unserem Unternehmen hat der Datenschutz einen besonders hohen
            Stellenwert. Eine Nutzung der Angebote ist ohne Namensnennung
            möglich. Sofern jedoch jemand ein spezielles Angebot über unsere
            Internetseite in Anspruch nehmen möchte, könnte dieVerarbeitung
            personenbezogener Daten erforderlich werden. Sobald dies
            erforderlich wird, holen wir eine Einwilligung der betroffenen
            Person ein. Die Verarbeitung personenbezogener Daten durch unser
            Unternehmen erfolgt im Einklang mit den gesetzlichenBestimmungen. In
            dieser Datenschutzerklärung informieren wir die Öffentlichkeit über
            Art, Umfang und Zweck der Verarbeitung personenbezogenen Daten,
            sofern sie von uns durchgeführt werden. Darüber hinaus klären wir
            betroffene Personen hiermitüber die ihnen zustehenden Rechte auf.
            Grundsätzlich setzen wir die uns zur Verfügung stehenden technischen
            und organisatorischen Möglichkeiten und Maßnahmen ein, um für einen
            möglichst lückenlosen Schutz personenbezogener Daten zu sorgen.Wir
            weisen jedoch darauf hin, dass Datenübertragungen via Internet
            dennoch Sicherheitslücken aufweisen können: ein absoluter Schutz
            kann daher nicht gewährleistet werden. Wir weisen daher auf die
            Möglichkeit hin, personenbezogene Daten zumBeispiel auch telefonisch
            oder per Postversand zu übermitteln. Die folgenden Punkte richten
            sich im Wesentlichen nach der Europäischen
            Datenschutz-Grundverordnung DS-GVO und nach den gesetzlichen
            Bestimmungen in Deutschland. Über die unten genanntenPunkte hinaus
            verweisen wir insbesondere auf das Artikel 6 I lit. f DS-GVO: „...
            die Verarbeitung ist zur Wahrung der berechtigten Interessen des
            Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
            Interessen oder Grundrechte und Grundfreiheiten der betroffenen
            Person, die den Schutz personenbezogener Daten erfordern,
            überwiegen, insbesondere dann, wenn es sich bei der betroffenen
            Person um ein Kind handelt.“ Auf eine an anderen Stellen bereits
            ausführlich dargestellten Begriffsbestimmungen verzichten wir.
          </p>
          <p className="imp-dat-sub-h">
            2. Name und Kontaktdaten der Verantwortlichen
          </p>
          <p className="imp-dat-text">
            Stefan Christiansen
            <br /> Österstraße 41-43
            <br /> 25704 Meldorf
            <br /> Tel.: 0 48 32 / 71 28
            <br /> Fax: 0 48 32 / 91 96
            <br /> E-Mail: info@christiansen-meldorf.de
          </p>
          <p className="imp-dat-sub-h">3. Kontaktmöglichkeiten</p>
          <p className="imp-dat-text">
            Unser Internetauftritt verfügt über Möglichkeiten, die eine schnelle
            elektronische Kontaktaufnahme zu unserem Unternehmen sowie eine
            unmittelbare Kommunikation mit uns ermöglichen, dies beinhaltet
            anklickbare E-Mail-Adressen. Im Falle eines
            entsprechendenfreiwilligen und demnach gewünschten Kontakts werden
            die von der betroffenen Person übermittelten personenbezogenen Daten
            automatisch gespeichert und anschließend für Zwecke der Bearbeitung
            oder der Kontaktaufnahme verwendet. Dabei erfolgt jedochkeine
            Weitergabe dieser personenbezogenen Daten an Dritte.
          </p>
          <p className="imp-dat-sub-h">
            4. Erfassung von allgemeinen Daten und Informationen
          </p>
          <p className="imp-dat-text">
            Wir erheben und speichern automatisch in ihren Server Log Files
            Informationen, die der Browser des Besuchers der Seiten an uns
            übermittelt. Diese Speicherung dient ausschließlich internen
            systembezogenen sowie statistischen Zwecken. Dies sind:
            <br />• Browsertyp und Browserversion,
            <br />• das von der betroffenen Person verwendete Betriebssystem,
            <br />• Referrer URL, also die zuvor besuchte Seite,
            <br />• die „Unterwebseiten“, die über ein zugreifendes System auf
            unserer Internetseite angesteuert werden,
            <br />• Hostname des zugreifenden Rechners / IP-Adresse,
            <br />• Name der jeweils abgerufenen Datei,
            <br />• die übertragene Datenmenge,
            <br />• Meldung über erfolgreichen Abruf,
            <br />• anfragende Domain,
            <br />• Datum und Uhrzeit der Serveranfrage,
            <br />• der Internet-Service-Provider des zugreifenden Systems
            <br />
            sowie ähnliche Daten und Informationen, die unter anderem der
            Gefahrenabwehr im Falle von Angriffen auf unsere
            informationstechnologischen Systeme dienen. Die Daten der
            Server-Logfiles werden getrennt von allen personenbezogenen Daten
            gespeichert.Eine Zusammenführung dieser Daten mit anderen
            Datenquellen wird nicht vorgenommen. Insofern sind all diese Daten
            für uns nicht bestimmten Personen zuordenbar. Wir benötigen die
            Logfiles und ihre Auswertung zum einen um die Qualität der
            Websiteaufrechterhalten zu können und die Funktionsfähigkeit zu
            erhalten, zum anderen um z. B. Strafverfolgungsbehörden im Falle
            eines EDV-gestützten Angriffs durch Dritte notwendigen Informationen
            bereitzustellen.
          </p>
          <p className="imp-dat-sub-h"> 5. Einsatz von Tools zur Webanalyse</p>
          <p className="imp-dat-text">
            Unsere Website benutzt Google Analytics, einen Webanalysedienst der
            Google Inc. (www.google.de). Google Analytics verwendet sog.
            „Cookies“, die eine Analyse der Benutzung der Website ermöglichen.
            Die somit erzeugten Informationen über die Benutzungdieser Website
            werden in der Regel an einen Server von Google in den USA übertragen
            und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung
            auf dieser Webseite, wird Ihre IP-Adresse von Google jedoch
            innerhalb von Mitgliedstaaten derEuropäischen Union oder in anderen
            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
            zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an
            einen Server von Google in den USA übertragen und dort gekürzt. Die
            IP-Anonymisierungist auf dieser Website aktiv. Im Auftrag des
            Betreibers dieser Website wird Google diese Informationen benutzen,
            um Ihre Nutzung der Website auszuwerten, um Reports über die
            Websiteaktivitäten zusammenzustellen und um weitere mit der
            Websitenutzungund der Internetnutzung verbundene Dienstleistungen
            gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von
            Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
            nicht mit anderen Daten von Google zusammengeführt. Im
            Zusammenhangmit dem Nicht-Zulassen oder Löschen von Cookies gelten
            die Anmerkungen von Abschnitt V. dieser Datenschutzerklärung.
            Außerdem können Nutzer die Verarbeitung der Daten durch Google
            verhindern, indem sie das unter dem folgenden Link
            verfügbareBrowser-Plug-In herunterladen und installieren:
            http://tools.google.com/dlpage/gaoptout?hl=de
          </p>
          <p className="imp-dat-sub-h">6. Automatische Datenlöschung</p>
          <p className="imp-dat-text">
            Wir speichern personenbezogene Daten nur für den Zeitraum, der nötig
            ist, um den Speicherungszwecks zu erfüllen oder aber um gesetzlichen
            Vorschriften zu genügen. Sollte der Speicherungszweck entfallen oder
            gesetzliche Fristen ablaufen, werden dieDaten routinemäßig gelöscht.
          </p>
          <p className="imp-dat-sub-h">
            7. Rechte der Nutzer unseres Internetauftritts (Betroffenenrechte)
          </p>
          <p className="imp-dat-text">
            Auskunft: Betroffene haben nach Artikel 15 DS - GVO das Recht, vom
            Verarbeiter Auskunft über die bei ihm gespeicherten
            personenbezogenen Daten zu erhalten.
            <br /> Berichtigung: Sollten Betroffene feststellen, dass die über
            sie gespeicherten personenbezogenen Daten falsch sind, müssen diese
            nach Artikel 16 DS - GVO berichtigt werden.
            <br /> Löschung („Recht auf Vergessenwerden“): Betroffene haben nach
            Artikel 17 DS - GVO das Recht, die Löschung ihrer Daten zu
            verlangen. Eine Löschung ist allerdings nur dann zulässig, wenn dem
            keine gese tzlichen Aufbewahrungsfristen
            entgegenstehen.Einschränkung der Verarbeitung: In bestimmten Fällen
            (z.B. wenn sich Betroffene und Datenverarbeiter nicht einig sind, ob
            die gespeicherten Daten richtig sind) haben Sie nach Artikel 18 DS -
            GVO ein Recht auf Einschränkung der Verarbeitung.
            <br /> Widerspruch: Sie können gemäß Artikel 21 DS - GVO der
            Verarbeitung Ihrer personenbezogenen Daten jederzeit mit Wirkung für
            die Zukunft widersprechen. Bitte beachten Sie, dass sie bei
            Widerspruch von für die Nutzung unseres Angebotes
            erforderlichenPflichtangaben das Angebot nicht mehr nutzen können.
            <br />
            Datenübertragbarkeit: Nach Artikel 20 DS - GVO besteht bei
            bestimmten Verarbeitungen ein Anspruch auf Datenübertragbarkeit.
            Diese betrifft nur Daten, die auf Basis einer Einwilligung oder zur
            Vertragserfüllung erhoben wurden.
            <br /> Wenn Sie von Ihren Betroffenenrechten Gebrauch machen
            möchten, wenden Sie sich bitte persönlich oder schriftlich an die
            Leitung unseres Unternehmens.
            <br /> Beschwerderecht: Sie sind berechtigt, bei der zuständigen
            Datenschutzaufsichtsbehörde Beschwerde einzureichen.
            <br /> Die zuständige Aufsichtsbehörde ist:
            <br /> ULD -Unabhängiges Landeszentrum für Datenschutz Schleswig
            Holstein Postfach 71 16
            <br /> 24171 Kiel
            <br /> Telefon: 0431 988 - 1200
            <br /> Fax: 0431 988-1223
            <br /> E-Mail: mail@datenschutzzentrum.de
            <br /> Webseite: www.datenschutzzentrum.de
          </p>
          <p className="imp-dat-sub-h">
            8. Dauer der Speicherung personenbezogener Daten
          </p>
          <p className="imp-dat-text">
            Die Speicherung von personenbezogenen Daten erfolgt entsprechend der
            jeweiligen gesetzlichen Aufbewahrungsfrist, sofern es eine solche
            Frist gibt. Nach Ablauf der Frist – oder sobald die nicht
            fristgebundenen Daten nicht mehr dem Zweck der Erhebung
            entsprechendbenötigt werden - werden die personenbezogenen Daten
            gelöscht, sofern sie nicht mehr zur Vertragserfüllung oder aber zu
            einer Vertragsanbahnung erforderlich sind.
          </p>
        </div>

        <Footer />
      </div>
    );
  }
}
