import React from "react";
import "./VerticalSpacer.css"

/**
 * ModoloooVerticalSpacer
 * @author so3nksen
 */
export default class ModoloooVerticalSpacer extends React.Component {
  render() {
  
      return (
          <div
            className="VerticalSpacer"
            style={{
              height: this.props.height
            }}
          >
              <div className="verticalspacer-inner"></div>
          </div>
      );
  }
}
