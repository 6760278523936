import React from "react";
import "./ModernHalfHalf.css";

export default class ModoloooModernHalfHalf extends React.Component {
  render() {
    if (this.props.orientation === "left") {
      return (
        <div className="ModernHalfHalf">
          <div className="modernhalfhalf-text-box modernhalfhalf-absolute-left"></div>
          <div
            className="modernhalfhalf-image-box modernhalfhalf-absolute-right"
            style={{ backgroundImage: `url(${this.props.img})` }}
          >
            <p className="modernhalfhalf-img-description">
              Besuchen Sie gerne
              <br />
              unser Ladengschäft
              <br />
              <br />
            </p>
          </div>
          <div className="modernhalfhalf-centered-content">
            <div className="modernhalfhalf-inner-left">
              <p className="modernhalfhalf-inner-left-headline">
                Alles für Ihr Zuhause
              </p>

              <p className="modernhalfhalf-inner-left-text">
                In userem Ladengeschäft finden Sie eine breite Auswahl an
                Haushalts- und TV-Geräten sowie Sicherheits- und
                Überwachungstechnik. Bei jedem Kauf werden Sie gründlich von
                einem Mitarbeiter unseres Hauses beraten. Darüber hinaus bringen
                wir Ihnen gern das Gerät, installieren es und entsorgen zudem
                Ihr Altgerät.
              </p>
            </div>
            <div className="modernhalfhalf-inner-right">
              <p className="modernhalfhalf-right-headline">
                50+ Jahre
                <br />
                Erfahrung
              </p>
            </div>
          </div>
        </div>
      );
    } else if (this.props.orientation === "right") {
      return (
        <div className="ModernHalfHalf">
          <div className="modernhalfhalf-text-box modernhalfhalf-absolute-right"></div>
          <div
            className="modernhalfhalf-image-box modernhalfhalf-absolute-left"
            style={{ backgroundImage: `url(${this.props.img})` }}
          >
            <p className="modernhalfhalf-img-description">
              Besuchen Sie gerne
              <br />
              unser Ladengschäft
              <br />
              <br />
            </p>
          </div>
          <div style={{ margin: "0 auto", width: "1024px", padding: "1px" }}>
            <div className="modernhalfhalf-inner-content modernhalfhalf-inner-right">
              <p className="modernhalfhalf-right-headline">
                30+ Jahre
                <br />
                Erfahrung
              </p>
              <p className="modernhalfhalf-inner-left-headline">
                Alles für Ihr Zuhause
              </p>

              <p className="modernhalfhalf-inner-left-text">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet.{" "}
              </p>
            </div>
            <div className="modernhalfhalf-inner-right"></div>
          </div>
        </div>
      );
    }
  }
}
